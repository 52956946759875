import React from "react"

/** props */
interface Props {
  icon: any
  iconTitle: string
  title: string
  subtitle: string
}

/** const */
const TimelineBlaineSection: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section
      id="blaine"
      className="container-fluid pt-5 pb-5"
      style={{ background: "linear-gradient(#EFF2F4, #EFF2F4)", color: "#365c70" }}
    >
      <div className="container pb-5">
        <div className="text-center pb-2">
          <Icon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#333333" }}
        >
          {props.iconTitle}
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)", marginTop: "10px", marginBottom: "10px" }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#365c70" }}>
          {props.title}
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.8" }}>
          {props.subtitle}
        </h4>
      </div>

      <div id="timeline">
        <div className="history-card-wrapper">
          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>01</span>
              </div>
              <h2>
                <span className="small">10,000 BC: Landscape Shaped by Glaciers</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/10000-3500bc-landscape-shaped-by-glaciers.png" alt="" />
              </div>
              <p>
                The Blaine area was covered by a large glacier which shaped the current landscape during the late
                Wisconsinan glaciation. The land used to be covered by 200 feet deep river valleys. The valleys filled
                with sediment. One such valley ran under Lino Lakes running northeast to southwest. As the glaciers
                retreated, the water gathered into a lake that covered much of Anoka county. Huge ice chunks were left
                in the wake of the glacier. These ice chunks melted and formed depressions that filled with water. This
                became the chain of lakes between Lino Lakes and Circle Pines.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>02</span>
              </div>
              <h2>
                <span className="small">1847: Land Survey Notes</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1847-land-survey-notes.png" alt="" />
              </div>
              <p>
                A survey was ordered for the land west of St. Croix River and east of the Mississippi River. Settlers
                were not allowed to occupy the land until after the survey was completed. The survey was completed and a
                description of Blaine was written. That description states: "This township presents a surface almost
                level to the eye of the beholder. It is dense marsh interspersed at intervals with numerous islands,
                small lakes, or ponds and tamarack swamps. The islands vary in size from one to ten acres and most of
                them covered by the thick brush and timber of various kinds. The water in the lakes and ponds is
                generally clear and cold and most of them have fish of various kinds. The margins of them are generally
                marshy and springy. The township is almost unacceptable for either men or beast except when frozen up
                and small portions of the northern portion of this town is barrens covered with short shrub and grasses
                and scattering white black and jack oak trees. The soil on the barrens is light-loose sand, third rate.
                A. J. Hewitt, Deputy Surveyor"[1]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>03</span>
              </div>
              <h2>
                <span className="small">1854: First Landowner</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1854-first-landowner.png" alt="" />
              </div>
              <p>
                The first land owner in Blaine was Truman Smith. He was a St. Paul banker who was a speculator in real
                estate. Truman played an important role in preventing a bill from getting passed that would have moved
                the capital from St. Paul to St. Peter. The proposed move was supported by the state congress and the
                governor because many owned land in St. Peter. A move would have surely increased the value of the land
                they owned. The bill was stopped by using procedural delays and then hidden in the top back room of the
                Fuller House Hotel until a move was no longer possible. Speculators hoped to earn a profit by buying
                land cheap and selling it high. Truman purchased the land for $1.25 per acre. Within a few years, the
                prices in the area rose to $15.00 per acre.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>04</span>
              </div>
              <h2>
                <span className="small">1862: First Settlers</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Phillip Laddy was the first person to live in Blaine. He moved to Laddie Lake (the lake was named after
                him) in 1862 but died soon after. In 1865, Greenberry Chambers became the first permanent settler. He
                was a slave born in Kentucky. He farmed and fought in the Civil War. After the Civil War, slaves were
                freed in 1865 with the passage of the 13th Amendment. Kentucky was a hostile place for black Americans
                as vigilantes were terrorizing them. Greenberry chambers moved to roughly the location of the Centennial
                High School campus.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>05</span>
              </div>
              <h2>
                <span className="small">1877: Blaine Township Forms</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1877-blaine-township-forms.png" alt="" />
              </div>
              <p>
                Before 1877, Blaine was a part of Anoka county. The landowners took action and in 1877 the new Blaine
                township was approved by the county and the state. During this time, the Blaine township population was
                128. The township was named after James Gillespie Blaine. James was a prominent Republican statesman
                from Maine. He was a member of congress and the speaker of the house. He was also a senator and
                secretary of state. He ran for president in 1884 but lost. By 1900, the population of Blaine was 374.[2]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>06</span>
              </div>
              <h2>
                <span className="small">1880: First Phone Lines</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                James Elwell strung some of Minnesota’s first phone lines. He ran a line between his Golden Lake and Ham
                Lake Stock Farms.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>07</span>
              </div>
              <h2>
                <span className="small">1880: First School House</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1880-first-school-house.png" alt="" />
              </div>
              <p>
                Frances Golden was the first school teacher for school district #48. Classes were held in his home. In
                1880, a one room school house was completed, and 12 students enrolled. It was originally located at the
                intersection of Golden Lake Road and East and West Golden Lake Roads. It was later moved to north of
                Lovell Road and Lexington Avenue. The building was moved by rolling it on logs pulled by oxen.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>08</span>
              </div>
              <h2>
                <span className="small">1884: Public Ditches & Lexington Avenue</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1884-public-ditches-lexington-avenue.png" alt="" />
              </div>
              <p>
                When settler’s first arrived, Blaine was too wet to farm. This made it near worthless. In 1884, Anoka’s
                first public ditch was created in the western part of Centerville. More ditches were dug which drained
                the land. James Elwell purchased land in 1996 and built two giant farms. One was in Ham Lake and the
                second was in the Blaine. He purchased 52,700 acres and dug 200 miles of ditches to make the land
                farmable. James then built an 8-mile road between his two farms, known as the Elwell Grade. This road
                became Lexington Avenue. It originally stretched from Lake Drive to Ham Lake.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>09</span>
              </div>
              <h2>
                <span className="small">1889: Early Church</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1884-early-church.png" alt="" />
              </div>
              <p>
                A wooden church was built on the western end of Centerville Township. The church was destroyed by heavy
                winds and rebuilt in 1897. Father Joseph Goiffon served the church. Father Goiffon lost his right leg
                from the knee down and his left foot at the ankle. They were frozen when he was caught in a blizzard for
                four days. He sliced open his dead horse to find shelter inside its corpse but couldn’t because it was
                too frozen. After his death in 1910, parishioners retained bits of his wooden legs as relics of his work
                with St. Joseph’s parish.[3]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>10</span>
              </div>
              <h2>
                <span className="small">1892: Proposed Railroad Defeated</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1892-proposed-railroad-defeated.png" alt="" />
              </div>
              <p>
                In 1892, a railroad was projected to go through the city of Blaine. The proposal was voted down limiting
                potential industry in Blaine.[4]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>11</span>
              </div>
              <h2>
                <span className="small">1901: First City Hall Built</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                After the formation of the Blaine township there was no official meeting place. Meetings were held in
                homes and eventually a school house. After being defeated in 1892, a city hall was approved in 1900. It
                was then built in 1901 for $350 at the intersection of Radisson Road and 101st. The city hall burned
                down and a second was built on 109th and Radisson Road.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>12</span>
              </div>
              <h2>
                <span className="small">1926: Murder During Prohibition</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1926-murder-during-prohibition.png" alt="" />
              </div>
              <p>
                Alcohol was outlawed in the United States during Prohibition. Crime rose during this time. About half of
                the criminal cases in Anoka county during this time were related to alcohol. Most cases ended with a
                guilty plea and a sentence of a $100 to $500 fine and 30 to 90 days in jail. Despite being illegal,
                alcohol was being made in Blaine in places like the Lovell farm, on the island in Peltier Lake, and on
                the James Elwell Golden Lake farm.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>13</span>
              </div>
              <h2>
                <span className="small">1926: The Messina Gang</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                The Messina gang was believed to be in control of the alcohol production for St. Paul. They had 12
                alcohol production plants including one in Blaine located at the Golden Lake Farm originally built by
                James Elwell. Willis Goff worked for the Messina gang. Willis Goff was arrested in a raid of one of the
                Messina’s facilities. The gang operated on the principle that arrested members would be taken care of
                financially. But Willis Goff didn’t receive his payments so he threatened to divulge information to
                federal agents. Later, federal agents raided more of the Messina’s facilities. The gang became convinced
                that Willis Goff was helping the agents. Then on August 29, they lured Willis Goff to the Golden Lake
                farm and shot him twice in the head. Heavily decomposed, his body was found on September 12 northwest of
                the intersection of Lexington and Lake Drive. Members of the Messina gang were convicted of crimes
                related to alcohol production but no one was convicted for the murder.[5]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>14</span>
              </div>
              <h2>
                <span className="small">1929: Tornado of 1929</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1929-tornado-of-1929.png" alt="" />
              </div>
              <p>
                Twin tornadoes hit Minnesota. They started in North Minneapolis and Lake Minnetonka then moved northeast
                through Blaine and up to Forest Lake. Seventeen people died and 160 people were injured. A mother and
                her child were thrown into Rice Lake by the tornado. They were remarkably unhurt. Katie Frischmon saw
                the tornadoes coming from the intersection of Lake Drive and Lexington Avenue. She states, “I saw it
                coming and it seemed the whole southwest end of Minnesota was about to be piled right on top of us. It
                made a hissing sound which grew louder and louder as it drew nearer.”[6]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>15</span>
              </div>
              <h2>
                <span className="small">1930: Construction of Highway 8</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1930-construction-of-highway-8.png" alt="" />
              </div>
              <p>
                The United States Highway system was officially adopted in November 1926. At the time of the adoption,
                highway 8 ran from Forest Lake to Wisconsin and beyond. In 1930, highway 8 was continued on to
                Minneapolis. This road was the predecessor to interstate 35W. Prior to the road being paved, it was a
                sandy rut. It ran from the corner of Lexington and Lowell Road directly east to Elwell’s farm, then to
                the northeast. Done by horse drawn scrappers, the road was improved to pave three lanes with a middle
                lane known as the suicide passing lane. When completed, interstate 35W replaced highway 8. The old
                highway 8 road is now Lake Drive.[7]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>16</span>
              </div>
              <h2>
                <span className="small">1950: Circle Pines Incorporated</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                In 1945, Valdermar Peterson was lying in the shade at the picnic ground of Golden Lake when an idea
                struck him. His idea was to build a cooperative community in the southeast corner of the Blaine
                township. He was frustrated by what he thought were social and economic injustices to the common man in
                a capitalist economic system. The homeowners would each own part of the association developing the
                community. A cooperative shopping center was built with a food store, restaurant, meat market, hardware
                store, and a service station. The community also built a three-room school house, a post office, and a
                credit union. The cooperative ran into financial and organizational difficulties and had to increase
                fees. A group of residents, discussing what could be done, decided to petition to incorporate the
                community into a city. The vote passed 89 to 5. On the day of the vote, Valdermar Peterson died but his
                vision endured.[8]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>17</span>
              </div>
              <h2>
                <span className="small">1950: Lexington Becomes a City</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Lexington is a small city next to Circle Pines that is in the former Blaine Township. Tensions grew
                between the areas of Lexington and Circle Pines. The Lexington area was populated before Circle Pines
                with people desperate in the World War II housing shortage. The housing was subpar with some houses said
                to have been put up so quickly that cement slabs were poured directly over core stubble. The people of
                Lexington saw Circle Pine’s cooperative ideals as socialistic. There were some talks on creating one
                city but residents on both sides saw that as repugnant. On May 5th, an election was held. The proposal
                to incorporate Lexington passed 93 to 61.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>18</span>
              </div>
              <h2>
                <span className="small">1952: Anoka County Airport Opens</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1952-anoka-county-airport-opens.png" alt="" />
              </div>
              <p>
                In 1939, the University of Minnesota established a pilot training program in response to a threat of
                world war. They purchased land and built an airport at the intersection of Highway 8 and County Road J
                in 1941. The 260-acre site lacked room for expansion, so discussions were held in 1948 to relocate.
                1,200 acres of land were purchased on the southern tip of Blaine township and the new airport opens in
                1952. The airport was named James Field in honor of Phillip James, director of the reliever airports for
                the Metropolitan Airports Commission. Flight training was discontinued in 1989 and the land was
                purchased by a Minneapolis businessman, Greg Herrick in 1996.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>19</span>
              </div>
              <h2>
                <span className="small">1954: Blaine Incorporates</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                With the incorporation of Circle Pines, Lexington, and the threat of land being lost to Spring Lake
                Park, the citizens of the township decide to incorporate. On January 28, Blaine was incorporated and
                Alfred Bernstein became the first mayor.[8]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>20</span>
              </div>
              <h2>
                <span className="small">1959: After World War II</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Housing developments sprang up. On May 1, Vern Donnay presented the village council a preliminary layout
                of land for 1,500 houses to expand eastward. The plans included layouts for parks, playgrounds, swimming
                pools, and a baseball diamond.[9]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>21</span>
              </div>
              <h2>
                <span className="small">1961: Bidding War with Coon Rapids for Library</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1961-bidding-war-with-coon-rapids-for-library.png" alt="" />
              </div>
              <p>
                The first Anoka County library was opened during 1958, in a rented space at 8409 Center Drive in Spring
                Lake Park. The city of Blaine and the city of Coon Rapids bid to relocate the library. Due to the
                central location and the low price, Blaine won. The Northtown Library continues to sit at 707 Highway 10
                in Blaine.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>22</span>
              </div>
              <h2>
                <span className="small">1961: 65-Hi Drive-In Theater</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Earliest records indicate that the land located at 10100 Central Ave NE once belonged to Irv Braverman.
                This mid-sized theater, with the capacity to hold 550 vehicles, had one screen and was open seasonally.
                The sound was transmitted by AM and FM radio. In 2002 the theater was demolished. What once was the
                entrance to the theater is now the entrance to a housing development.[10]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>23</span>
              </div>
              <h2>
                <span className="small">1972: Northtown Mall Opens</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1972-northtown-mall-opens.png" alt="" />
              </div>
              <p>
                In 1965, California real estate developer, Robert Muir, purchased 137 acres for a mall. He chose the
                location after flying his helicopter and seeing the major roads intersecting the area. It took $30
                million and 500 local workers to build the 900,000 square foot mall. Once the first phase was completed,
                there was an impressive grand opening that included TV stars, singers, local mayors and the governor of
                Minnesota. The building had a sunken aquarium area, a center court fountain, and a 30-foot replica of
                the Titanic from the 1953 movie.[11]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>24</span>
              </div>
              <h2>
                <span className="small">1990: National Sports Center Opens</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/1990-national-sports-center-opens.png" alt="" />
              </div>
              <p>
                In 1987, the state legislator passed legislation that created the Minnesota Amateur Sports Commission.
                As part of the initiative, $14.7 million was appropriated for the construction of the National Sports
                Center. Once built, it became the world’s largest amateur sports facility. It has hosted United States
                Olympic Trial events, world class weightlifting, and it’s the location for soccer’s USA CUP. In the
                first year, the facility welcomed 386,000 visitors. Its growth has exploded, and it welcomes ten times
                more visitors just two decades later.[12]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>25</span>
              </div>
              <h2>
                <span className="small">2002: New City Hall</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/blaine/2002-new-city-hall.png" alt="" />
              </div>
              <p>
                In 1997, the groundwork began to design a new city hall. The building was completed in 2002 at a cost of
                approximately $15,000,000. It faces the site of the old town hall, located on 109th near Radisson Road.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5">
        <div className="container">
          <div>
            <strong>Sources:</strong>
          </div>
          <ol>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              U.S. Bureau of Land Management - Survey Plats and Field Notes.
              https://glorecords.blm.gov/details/survey/default.aspx?dm_id=108941&sid=001nvecs.3nl
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Upham, Warren. Minnesota Geographic Names: Their Origin and Historic Significance. Minnesota Historical
              Society, 1979.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Wielenberg, Steven M. “Fr Joseph Goiffon.” Find A Grave, 9 Oct. 2013.
              https://www.findagrave.com/memorial/118444244/joseph-goiffon
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              “The Story of Blaine, Minnesota.” Blaine Historical Society.
              http://www.blainehistory.org/Story-of-Blaine.html
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              “Albert Lea Evening Tribune Newspaper Archives, Sep 13, 1926.” NewspaperArchive, 13 Sept. 1926.
              http://newspaperarchive.com/albertlea-evening-tribune-sep-13-1926-p-1/
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              “Madera Tribune, Volume XLIII, Number 130, 6 April 1929.” Many Are Killed And Injured by Tornadoes In
              Middlewest States. http://www.cdnc.ucr.edu/cgi-bin/cdnc?a=d&d=MT19290406.2.5
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Munsch, Andrew. Historic U.S. 61: Old Highway 61 - Wyoming to the St. Louis County Line, Dead Pioneer.
              http://www.deadpioneer.com/routes/US8/
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              {" "}
              Brown, Curt. “Stories Deep beneath Centennial High School.”Star Tribune, Star Tribune, 26 June 2016.
              https://www.startribune.com/stories-deep-beneath-centennial-high-school/384400841/
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              “The Story of Blaine, Minnesota.” Blaine Historical Society.
              http://www.blainehistory.org/Story-of-Blaine.html
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              {" "}
              “65-Hi Drive-In, Blaine.” Minnesota Drive-In Theaters, 13 July 2017.
              http://minnesotadriveins.com/index.php/65-hi-drive-in-blaine/
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Crex Carpet Company. www.mnopedia.org/group/crex-carpet-company
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              {" "}
              National Sports Center. Blaine, Minnesota. SportsEngine. https://www.nscsports.org/history
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              he pictures used above came from the following sources: Anoka County Historical Society and the Blaine
              Historical Society.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              A special thanks is given to the Anoka County Library for using the following source. It was used
              throughout this timeline. This book is a great source to learn more about the history of Anoka County.
              Lee, Stephen J. History of Circle Pines and Lexington Minnesota Full Version. 2000, Aug 21.
            </li>
          </ol>
        </div>
      </div>
    </section>
  )
}
/** export */
export default TimelineBlaineSection
